.file_drag_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.file_drag_container_link {
  background-color: #e6e6e6;
  cursor: pointer;
  padding: 5px 10px;
}

.ql-editor {
  min-height: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.textarea-emoji-control {
  right: 75px !important;
  top: -35px !important;
  position: absolute !important;
}

#textarea-emoji {
  top: 0px !important;
}
.py-2 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.ant-table-row .delete-icon {
  visibility: hidden;
}

.ant-table-row:hover .delete-icon,
.delete-icon:hover,
.ant-popover-open ~ .delete-icon,
.ant-popover-open + .delete-icon,
.delete-icon:has(.ant-popover-open) {
  visibility: visible !important;
}

.pagination-size-changer .ant-select-selector {
  cursor: pointer !important;
}

.reaction-container {
  display: flex;
  gap:5px;
}


// checkbox customizing 
.todo-row .ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border: 2px solid #4cc11e;
}
.todo-row .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  border: none;
  background: #4cc11e;
}

.todo-row .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 8px;
  height: 15px;
}

.todo-row .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

// input addon
.todo-row .ant-input-group .ant-input-group-addon {
  background: #ffa629;
  border: 1px solid #ffa629;
}

.todo-row {
  border-top: 1px solid #e2e2e2;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 767px) {
  .reaction-container {
    display: flex;
    gap:3px;
  }

  .todo-row {
    border-top: 1px solid #e2e2e2;
    padding-left: 15px;
    padding-right: 15px;
  }

  .todo-row .ant-checkbox .ant-checkbox-inner {
    width: 15px;
    height: 15px;
    border: 1px solid #4cc11e;
  }

  .todo-row .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
    width: 5px;
    height: 10px;
  }
}