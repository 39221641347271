body {
  margin: 0;
  font-family: "Petlink", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brann-page-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.brann-logo-wrapper {
  margin-left: 30px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
}

.brann-registeration-step-wrapper {
  margin: 2rem 0;
  .ant-steps {
    max-width: 350px;
    margin: 0 auto;

    .ant-steps-item {
      margin: 0.75rem 0;
      opacity: 0.5;

      .ant-steps-icon {
        color: gray !important;
      }

      &.ant-steps-item-active {
        opacity: 1;
        .ant-steps-icon {
          color: green !important;
        }
      }

      .ant-steps-item-title {
        color: white !important;
      }
      .ant-steps-item-description {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }
}

.brann-paper {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.brann-box-paper {
  background: #f5f5f5;
  padding: 20px;
}

.brann-card-paper {
  background: #fff;
  padding: 16px;
  // height: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.brann-paper-grey {
  background: #f5f5f5;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.brann-paper-with-scroll {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.brann-circle-paper {
  background: #fff;
  padding: 120px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.brann-circle-icon-box {
  display: flex;
  padding: 6px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  border: 1px solid;
}

.brann-content {
  background: #ffffff;
  padding: 24px;
}

.brann-plan-paper {
  padding: 30px;
}

.brann-centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brann-link:hover {
  color: #fcd4b8 !important;
}

.brann-link-blue {
  color: blue !important;
  // text-decoration: underline !important;
}

.brann-link-blue:hover {
  color: #6a5999 !important;
}

.brann-link-black {
  color: black !important;
  text-decoration: underline !important;
}

.brann-link-black_button {
  color: black !important;
  // text-decoration: underline !important;
}

.brann-link-black:hover {
  color: #6a5999 !important;
}

.brann-link-button {
  padding: 12px 24px;
  border: none;
  background-color: #e0e0e0;
  color: black;
}

.brann-primary-button {
  background-color: #010101;
  color: #fff;
  &:hover {
    background-color: #ff8d00 !important;
  }
}

.brann-danger-button {
  background-color: #fa5e5e;
  color: #fff;
  &:hover {
    background-color: #ff8d00 !important;
  }
}

.brann-gray-button {
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  color: #000;
  &:hover {
    background-color: #ff8d00 !important;
  }
}

.brann-active-button {
  background-color: #4bc11f;
  color: #fff;
  &:hover {
    background-color: #ff8d00 !important;
  }
}

.brann-secondary-button {
  border: 1px solid #010101 !important;
  background-color: transparent;
  color: #010101;
  &:hover {
    background-color: #010101 !important;
    color: #fff !important;
  }
}
.brann-secondary-button-color {
  border: 1px solid #ff8d00 !important;
  background-color: transparent;
  color: #010101;
  width: 200px;
  &:hover {
    background-color: #ff8d00 !important;
    color: #010101 !important;
  }
}

.brann-border-button {
  border: 1px solid #ff8d00 !important;
  background-color: transparent;
  color: #010101;
  &:hover {
    background-color: #ff8d00 !important;
    color: #010101 !important;
  }
}

.brann-custom-button {
  border: 1px solid #ff8d00 !important;
  background-color: transparent;
  color: #000000;
  width: 100%;
  &:hover {
    background-color: #ff8d00 !important;
    color: #000000 !important;
  }
}

.ant-menu-item {
  color: #fff;
  margin: 10px 0px !important;
  border-left: 5px solid transparent;
  svg {
    min-width: 20px;
  }
  span {
    margin-left: 10px;
    font-size: 16px;
  }
}
.ant-menu-item-selected {
  background-color: transparent !important;
  border-left: 5px solid #ff8d00;
  color: #ff8d00 !important;
}

.ant-table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ant-progress {
  .anticon {
    margin: 0px !important;
  }
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-dropdown-button {
  button {
    background: #ff8d00;
    &:before {
      background-color: #ccc !important;
    }
  }
}

.quill {
  display: flex;
  flex-direction: column-reverse;
  .ql-container {
    background: #fff;
    border-top: 1px solid #ccc !important;
  }
  .ql-toolbar {
    border-top: 0 !important;
  }
}

.app-layout {
  min-height: 100vh;
  .ant-layout-sider,
  .ant-menu {
    background: #010101;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 24px;
    height: 80px;
    .nav {
      margin-left: 20px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
  }
  main {
    // height: calc(100vh - 80px);
    overflow-y: hidden;
    padding: 32px;
  }
}

.header-menu {
  & ul > li {
    svg {
      min-width: 20px;
    }
    span {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.vertical-align-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.align-items {
  display: flex;
  align-items: center;
}

.flex-row {
  // display: flex;
  gap: 10px;
}

.linkable-text {
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}

.ck.ck-editor {
  width: 100%;
}
.ck-editor__editable {
  min-height: 150px;
  max-height: 150px;
  // width: 100%;
}

.full-width {
  width: 100%;
}

.ant-form-vertical .ant-form-item-label > label {
  width: 100%;
}