@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables";
@import "./global";
@import "./auth";
@import "./customer";
@import "./organization";
@import "./overview";
@import "./property";
@import "./task";
@import "./offer.scss";
