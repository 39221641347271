@font-face {
  font-family: "Petlink-Bold";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Petlink-Medium";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Petlink";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

$primary-color: #ff8d00;
